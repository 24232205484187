<template>
	<div class="tcontainer">
		<Toast />
		<div class="clearfix ttitle">
			<div class="pull-right" style="margin: 5px;">
				
			</div>
			<div class="title">Transaction Audit</div>
		</div>
		<table class="table table-bordered table-striped">
			<thead>
				<tr>
					<th>Id</th>
					<th>Center Name</th>
					<th>Contact Person</th>
					<th>Phone</th>
					<th>Error Amount</th>
					<th>Adjust</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="row in rows" :key="row">
					<td>{{ row.id }}</td>
					<td>{{ row.name }}</td>
					<td>{{ row.contact_person }}</td>
					<td>{{ row.phone }}</td>
					<td>{{ row.error_amount }}</td>
					<td><button type="button" class="btn btn-info" @click="adjustBalance(row)">Adjust</button></td>
				</tr>
			</tbody>
		</table>
	</div>
</template>
<script>
import Toast from 'primevue/toast';
export default {
	components: {
		Toast,
	},
	data() {
		return {
			dateRange: "",
			totalReports: 0,
			rows: [],
			centers: [],
			testPrice: 0,
			doctorPrice: 0,
			agentPrice: 0,
			isSuper: false,
		}
	},
	mounted() {
		this.loadLazyData();
	},
	methods: {
		enter() {
			let user = JSON.parse(window.localStorage.admin_access_token);
			this.isSuper = user.is_super == 'y';
		},
		async loadLazyData() {
			let data = {};
			let res = await this.post("admin/transacton-audit", data);
			this.rows = res.rows;
		},
		async adjustBalance(row){
			await this.post('admin/adjust-balance', {id: row.id});
			this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Balance adjusted successfully.', life: 3000 });
			let delrow = this.rows.find(fr => fr.id == row.id);
			this.rows.splice(this.rows.indexOf(delrow), 1);
		}
	},
}
</script>